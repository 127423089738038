import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2022_02_24_STI-trichomonas.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
	<StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">Due to the challenges of traditional Trichomonas diagnostic methods, women with this infection may be undertreated. 
					This can lead to adverse health outcomes, inability to initiate expedited partner treatment, and persistence of the infection in sexual networks.
					</p>
					<p data-aos="fade-up">
					In addition to providing updates on epidemiology, clinical relevance, and treatment recommendations, this webinar will examine the challenges 
					associated with Trichomonas diagnosis, discuss issues surrounding drug resistance, and review patient management including the 2021 CDC 
					guidelines for Trichomonas testing &amp; treatment.
					</p>

					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Review Trichomoniasis epidemiology – US and worldwide</li>
						<li>Examine adverse health outcomes for women </li>
						<li>Examine diagnosis and challenges associated </li>
						<li>Review issues surrounding drug resistance</li>
						<li>Review patient management – 2021 CDC guidelines for Trichomonas testing &amp; treatment</li>
						<li>Evaluate a modern approach to outsmart Trichomonas</li> 
					</ul>
				  </div>
				</div>
			
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/christina-munzy.jpg"
					width={200}
					alt="Dr. Christina Muzny"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Christina Muzny obtained her medical degree at the Texas A&M University Health Sciences Center College of Medicine. She subsequently 
				completed an internal medicine residency and an infectious diseases fellowship at the University of Mississippi Medical Center prior to joining the infectious diseases 
				faculty at the University of Alabama at Birmingham (UAB) in 2010. She has since obtained a Masters of Science in Public Health (Epidemiology) at the UAB School of Public Health. </p>

				<p data-aos="fade-up">Her clinical and research interests focus on the epidemiology, diagnosis, and treatment of HIV and sexually transmitted infections (STIs) 
				in women, specifically vaginal infections including bacterial vaginosis (BV) and trichomoniasis. Dr. Muzny currently has R01 and R21 funding from NIH/NIAID to study 
				the pathogenesis of BV. She has also recently participated in multiple clinical trials regarding use of 5-nitroimidazoles, including secnidazole, for the treatment 
				of vaginal trichomoniasis in women. </p>

				<p data-aos="fade-up">Dr. Muzny is a tenured Associate Professor in the Division of Infectious Diseases at UAB with a secondary appointment in the 
				UAB Department of Epidemiology. She is a medical consultant for the 2021 CDC STI Treatment Guidelines as well as a teaching faculty for the Alabama-North 
				Carolina STD/HIV Prevention Training Center and an Associate Scientist for the UAB Center for AIDS Research.</p>

				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
  if(localStorage.getItem(this.props.localStorageID)) {
		this.setState({
				viewAllowed: true
			})
		}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
    />
  )
}

export default FullBody
